const emoji_categories = [
	{ name: "Smileys & Emotion", icon: "😀" },
	{ name: "People & Body", icon: "🧑" },
	{ name: "Animals & Nature", icon: "🐈" },
	{ name: "Food & Drink", icon: "🍕" },
	{ name: "Travel & Places", icon: "🚖" },
	{ name: "Activities", icon: "🏉" },
	{ name: "Objects", icon: "💡" },
	{ name: "Symbols", icon: "♻️" },
	{ name: "Flags", icon: "🏴" }
];

const skin_tones = [
	{ name: "No skin tone", icon: "🧑" },
	{ name: ": Light skin tone", icon: "🧑🏻" },
	{ name: ": Medium-light skin tone", icon: "🧑🏼" },
	{ name: ": Medium skin tone", icon: "🧑🏽" },
	{ name: ": Medium-dark skin tone", icon: "🧑🏾" },
	{ name: ": Dark skin tone", icon: "🧑🏿" }
];

export { emoji_categories, skin_tones };
