<template>
	<div>
		<highcharts :options="ChartOptions"></highcharts>
	</div>
</template>

<script>
    import {Chart} from './src'

    export default {
        name: "circle-chart",
		props: {
			data	: { required: true, type: Array },
			name	: { default: '' },
        },
        data() {
            return {
                ChartOptions: {
					colors	: ['#48a6e8', '#0d4770', '#57a869', '#001a41'],
					chart	: { plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false, type: 'pie' },
                    title	: { text: null },
                    tooltip	: { headerFormat: '', pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' + 'Leads: <b>{point.y}</b><br/>' },
                    plotOptions: {
                        pie	: { allowPointSelect: true, cursor: 'pointer', dataLabels: { enabled: false }, showInLegend: true }
                    },
                    series: [{
                        dataLabels	: { enabled: false },
                        minPointSize: 0,
                        showInLegend: true,
                        innerSize	: '50%',
                        zMin		: 0,
                        name		: this.name,
                        data		: this.data
                    }]
                }
            }
        },
        components: {
            highcharts: Chart
        },
		mounted() {
			if( typeof( document.getElementsByClassName('highcharts-credits')[0] ) != 'undefined' ) {
				document.getElementsByClassName('highcharts-credits')[0].innerHTML = ''
			}
		}
    }
</script>

<style>
    .highcharts-credits {
		display: none;
	}
</style>
