import vueDraggable from './vuedraggable'
import VueColor 	from './vue-color/ColorPicker.vue'

let components = {};

export default components;

export {
	vueDraggable,
	VueColor
};
