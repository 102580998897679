<template>
	<div class="d-inline-block">
		<button @click="buttonClicked" class="btn facebook-button">
			<div class="spinner-fb" v-if="isWorking"></div>
			<img :src="icon" v-if="!isWorking"> <div>{{ getButtonText }}</div>
		</button>
	</div>
</template>
<script>
import { loadFbSdk, getLoginStatus, fbLogout, fbLogin } from './helpers.js'
import icon from './icon.png'
export default {
	name: 'facebook-login',
	props: {
		appId		: { type: String, required: true },
		version 	: { type: String, default: 'v5.0' },
		logoutLabel	: { type: String, default: 'Log out from Facebook' },
		loginLabel	: { type: String, default: 'Login with Facebook' }, // Connect to Facebook, Sign in with facebook
		loginOptions: { type: Object, default: function() {
			return { scope: 'email' }
		}},
		disconnect	: { type: Boolean, default: false }
	},
	data() {
		return {
			isWorking: false,
			isConnected: false,
			icon
		}
	},
	computed: {
		getButtonText() {
			switch (this.isConnected) {
				case true:
					return this.logoutLabel;
				case false:
					return this.loginLabel;
				default:
					return 'Facebook';
			}
		}
	},
	methods: {
		buttonClicked() {
			this.$emit('click')
			if (this.isConnected) {
				this.logout();
			} else {
				this.login();
			}
		},
		logout() {
			this.isWorking = true;
			fbLogout().then(response => {
				this.isWorking = false;
				this.isConnected = false;
				this.$emit('logout', response)
			});
		},
		login() {
			this.isWorking = true;
			fbLogin(this.loginOptions).then(response => {
				if (response.status === 'connected') {
					this.isConnected = true;
				} else {
					this.isConnected = false;
				}
				this.isWorking = false;
				this.$emit('login', response) // { response, FB: window.FB }
			});
		},
		handleResponse (response) {
			let that = this
			if (response.status === 'connected') {
				that.isConnected = true;
			}
			that.$emit('get-init', response);
			that.$emit('sdk-loaded', { isConnected : that.isConnected, FB : window.FB })
			that.isWorking = false;
		}
	},
	watch : {
		disconnect(val) {
			if( val ) {
				this.logout()
			}
		}
	},
	mounted() {
		let that = this
		that.isWorking = true;
		loadFbSdk(that.appId, that.version).then((/* LoadingResult */) => {}).then(() => getLoginStatus()).then(response => {
			that.handleResponse(response)
		});
		
		setTimeout( function() {
			if( that.isWorking ) {
				if( typeof( window.FB ) === 'undefined' ) {
					that.isWorking = false
					return false
				}
				window.FB.getLoginStatus(response => {
					that.handleResponse(response)
				})
			}
		}, 3000 )
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.facebook-button {
		
		border: none;
		height: 46px;
		
		color: #FFF;
		min-width: 225px;
		/* background-image: linear-gradient(#4C69BA, #3B55A0); */
		background: #1877f2; /* #3C57A4 */
		
		
		
		/* box-shadow: 1px 1px 6px #3c57a4; */
		font-family: sans-serif;
		display: flex;
		
		padding: .5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: .3rem;
		font-weight: 400;
		
		div {
			margin: auto 5px;
		}
	}
	.facebook-button:hover {
		color: #fff !important;
	}

	.spinner-fb {
		box-sizing: border-box;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		border: 4px solid #f3f3f3;
		border-top: 4px solid #3498db;
		animation: spin 2s linear infinite;
	}

	img {
		width: 20px;
		margin: auto 5px;
	}
 
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
